import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

import { Button, LinkText } from "@/components/ui";
import { getPageRoute } from "@/utils/constants/pageRoutes";
import { useGetLanguageByKey } from "@/utils/i18n/languages";

interface ICustomError {
  type: "404" | "500" | "maintenance";
}

const CustomError = ({ type }: ICustomError) => {
  const { t } = useTranslation();
  const getLanguageByKey = useGetLanguageByKey();

  const router = useRouter();
  const region = router?.query?.region || "nl";
  const lang = router?.query?.lang || "nl-NL";

  const salesEmail = getLanguageByKey(router.locale as string)?.supportEmail;

  return (
    <div data-testid="customError" className="w-full text-center">
      <div className="flex flex-col items-center">
        <h1 className="mb-8 flex flex-col gap-[26px] text-3xl text-black md:max-lg:mb-14">
          <span className="text-[55px]">
            {t(`errorPages.v2.${type}.title`)}
          </span>
          <span>{t(`errorPages.v2.${type}.subtitle`)}</span>
        </h1>
        <p className="text-black">{t(`errorPages.v2.${type}.description`)}</p>
        {type !== "maintenance" && (
          <div className="mt-14 flex flex-col items-center gap-8">
            <Button
              onClick={() => {
                router.push(`mailto:${salesEmail}`);
              }}
            >
              {t("errorPages.v2.contactButton")}
            </Button>
            <LinkText
              pathName={getPageRoute("login", String(region), String(lang))}
            >
              {t("errorPages.v2.linkHome")}
            </LinkText>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomError;
