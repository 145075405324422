import React from "react";
import { type GetStaticProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import CustomError from "@/components/custom-error/CustomError";
import LogoBlack from "@/components/ui/Logo";
import PreLoginPage from "@/components/wrappers/PreLoginPage";

const Custom404 = () => (
  <PreLoginPage>
    <div className="mx-auto flex max-w-[364px] flex-col md:max-w-[432px] lg:max-w-[415px]">
      <div className="mx-auto mb-16">
        <LogoBlack width={110} />
      </div>
      <div>
        <CustomError type="404" />
      </div>
    </div>
  </PreLoginPage>
);

export default Custom404;

export const getStaticProps: GetStaticProps = async ({ params }) => ({
  props: {
    region: params?.region || "nl",
    lang: params?.lang || "nl",
    ...(await serverSideTranslations(String(params?.lang) || "nl-NL")),
  },
});
